import React from "react";


const Slider = () => {
  return (
    <>
      {/*  Home Banner */}
      
      {/* End Home Banner  */}
    </>
  );
};

export default Slider;
